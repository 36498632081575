import Vue from 'vue'
import VueRouter from "vue-router"
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia, PiniaVuePlugin } from "pinia"
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import "./assets/css/main.css"

import { domain, clientId } from './../config_auth/auth_config.json';
import { Auth0Plugin } from './auth';

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.use(VueRouter)

Vue.config.productionTip = false

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetPath
        ? appState.targetPath
        : window.location.pathname
    );
  }
});

new Vue({
  router,
  vuetify,
  pinia,
  render: h => h(App)
}).$mount('#app')
