<template>
  <v-stepper v-model="stepper" :elevation="0" :flat="true">
    <v-stepper-header style="display: none">
      <v-stepper-step :complete="stepper > 1" step="1">
        <!-- QUOTE -->
      </v-stepper-step>

      <v-stepper-step :complete="stepper > 2" step="2">
        <!-- RECEIPT -->
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <!-- 
        v-stepper-content spreads
      -->

      <!-- QUOTE -->
      <v-stepper-content
        class="pa-0"
        style="background: #1e1e1e !important"
        step="1"
      >
        <!-- FORM -->
        <rfq-form
          :isFxndf="isBlotterFXNDF"
          :rfqcloudUser="getAuth"
          ref="rfqFormFXSpot"
          :comboBoxItems="comboBoxItems"
          :isQuoteRequested="isQuoteRequested"
          :loadingQuote="loadingQuote"
          :enableBrlCcy1="enableBrlCcy1"
          @quote="quote($event)"
        />

        <!-- PRICES -->
        <rfq-prices
          ref="rfqPrices"
          :isFxndf="isBlotterFXNDF"
          :isQuoteRequested="isQuoteRequested"
          :loadingQuote="loadingQuote"
          :loadingAcceptQuote="loadingAcceptQuote"
          :loadingDecline="loadingDecline"
          :quoteLastUpdate="quoteLastUpdate"
          :buyCcy="buyCcy"
          :sellCcy="sellCcy"
          :rounded="'lg'"
          @acceptQuote="acceptQuote()"
          @declineQuote="declineQuote($event)"
          @errorAnimationTimeout="errorAnimationTimeoutCallback()"
        />

        <fetch-fail 
          :error="requestFail"
        >
        </fetch-fail>
      </v-stepper-content>

      <!-- RECEIPT -->
      <v-stepper-content
        class="pa-0"
        style="background: #1e1e1e !important"
        step="2"
      >
        <rfq-receipt-template
          :isRfqCloud="true"
          @newRfq="newRfqCallback()"
        >
          <template v-slot:content>
            <rfq-receipt-FXSpot 
              v-if="isBlotterFXSpot"
              :isRfqCloud="true"
              ref="rfqReceipt" 
            >
            </rfq-receipt-FXSpot>

            <rfq-receipt-FXNDF 
              v-else
              :isRfqCloud="true"
              ref="rfqReceipt" 
            >
            </rfq-receipt-FXNDF>
          </template>
        </rfq-receipt-template>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { mapState, mapActions } from "pinia"
import { useDisplayStore, useAuthStore, useRfqFormStore, EventSourcePolyfill } from "../store/index"
import { SECRETKEY, API_PLATFORM_HOST, API_RFQCLOUD_HOST } from "./../store/index.js"
import RfqReceiptTemplate from "./common/RfqReceiptTemplate.vue"
import RfqReceiptFXSpot from "./common/RfqReceiptFXSpot.vue"
import RfqReceiptFXNDF from "./common/RfqReceiptFXNDF.vue"
import RfqPrices from "./common/RfqPrices.vue"
import RfqForm from "./common/RfqForm.vue"
import FetchFail from "./common/FetchFail.vue"

export default {
  components: {
    RfqReceiptTemplate,
    RfqPrices,
    RfqForm,
    RfqReceiptFXSpot,
    RfqReceiptFXNDF,
    FetchFail
  },
  data () {
    return {
      apiSecretKey: undefined,
      apiHost: undefined,
      apiRfqHost: undefined,
      // form
      stepper: 1,
      comboBoxItems: [
        {
          text: "Banco Safra",
          value: "safra"
        }
      ],
      isQuoteRequested: false,
      loadingQuote: false,
      newRfqPayload: undefined,
      // prices
      eventSourceQuoteFeed: undefined,
      quoteLastUpdate: undefined,
      buyCcy: "",
      sellCcy: "",
      quoteFeedResponseEvent: undefined, // TODO: rename to quoteEventData
      loadingAcceptQuote: false,
      loadingDecline: false,
      quoteRequestFail: false,
      acceptQuoteRequestFail: false,
      nothingDoneRequestFail: false,
      enableBrlCcy1: false
    }
  },
  watch: {
    isQuoteRequested: {
      handler (newValue) {
        if (newValue) {
          this.emitDisableActionsEvent()
          return
        }
        if (!this.loadingDecline && !this.loadingAcceptQuote) {
          this.emitEnableActionsEvent()
        }
      }
    },
    loadingAcceptQuote: {
      handler (newValue) {
        if (newValue) {
          this.emitDisableActionsEvent()
          return
        }
        if (!this.loadingDecline && !this.isQuoteRequested) {
          this.emitEnableActionsEvent()
        }
      }
    },
    loadingDecline: {
      handler (newValue) {
        if (newValue) {
          this.emitDisableActionsEvent()
          return
        }
        if (!this.isQuoteRequested && !this.loadingAcceptQuote) {
          this.emitEnableActionsEvent()
        }
        // this.emitEnableActionsEvent()
      }
    },
    stepper: {
      handler (newValue) {
        if (newValue === 2) {
          this.$emit("isReceipt")
          return
        }
        this.$emit("isForm")
      }
    }
  },
  computed: {
    ...mapState(useDisplayStore, [
      "isMobile",
      "showLayoutElements"
    ]),
    ...mapState(useAuthStore, [
      "getBlotter",
      "getAuth",
      "isBlotterFXSpot",
      "isBlotterFXNDF"
    ]),
    requestFail () {
      return this.quoteRequestFail || this.nothingDoneRequestFail || this.acceptQuoteRequestFail
    }
  },
  mounted () {
    this.apiSecretKey = SECRETKEY
    this.apiHost = API_PLATFORM_HOST
    this.apiRfqHost = API_RFQCLOUD_HOST
    this.getProductFeature()
    .then((response) => {
      const productFeature = response.data
      this.enableBrlCcy1 = productFeature.brl_currency === 1
    })
  },
  methods: {
    ...mapActions(useRfqFormStore, [
      "newRfq",
      "nothingDoneRfq",
      "newOrderRfq",
      "getProductFeature"
    ]),
    quote (payload) {
      this.quoteRequestFail = false
      this.isQuoteRequested = true;
      this.loadingQuote = true;
      this.newRfqPayload = payload;
      const clientSide = payload.rfq_side
      const ccy = payload.rfq_symbol.slice(0, 3)
      this.newRfq(payload)
        .then((response) => {
          const data = response.data;
          this.newRfqResponse = data;
          const id = data.rfq_id;
          this.listenQuoteFeed(id, clientSide, ccy);
        })
        .catch((error) => { // TODO: Exibir uma mensagem de erro
          console.log(error);
          this.isQuoteRequested = false;
          this.loadingQuote = false;
          this.quoteRequestFail = true
        });
    },
    listenQuoteFeed (id, clientSide, ccy) {
      const url = `${this.apiRfqHost}/rfqtest/rfq/quotefeed/${id}`;
      this.eventSourceQuoteFeed = new EventSourcePolyfill(url);
      this.eventSourceQuoteFeed.addEventListener("message", (event) => {
        let data = JSON.parse(event.data)
        this.loadingQuote = false
        if (data.type === "reject") {
          this.setErrorAnimation()
          return
        }
        if (data.type === "quotecancel") {
          // this.resetPricingStates()
          this.eventSourceQuoteFeed.close()
          this.setTimeoutAnimation()
          return
        }
        if (data.type === "quote") {
          this.quoteLastUpdate = data.timelast;
          this.quoteFeedResponseEvent = data;
          if (clientSide === 1) {
            // const priceAndBips = this.getPriceAndBips(data.bid);
            this.buyCcy = ccy
            this.setBuyData(data.bid);
            if (this.isBlotterFXNDF)
              this.setFwdBuyData(data.bid_fwd_points, data.bid_spot);
            return;
          }
          // const priceAndBips = this.getPriceAndBips(data.offer);
          this.sellCcy = ccy
          this.setSellData(data.offer);
          if (this.isBlotterFXNDF)
            this.setFwdSellData(data.offer_fwd_points, data.offer_spot);
        }
        if (data.type === "execreport") {
          this.eventSourceQuoteFeed.close();
          return;
        }
      }, false);
      this.eventSourceQuoteFeed.addEventListener("disconnected", () => {
        this.declineQuote("Declined due to idle time");
        this.isQuoteRequested = false;
        this.resetPricingStates()
        this.eventSourceQuoteFeed.close();
      });
    },
    declineQuote (declineMsg) {
      this.loadingDecline = true
      this.nothingDoneRequestFail = false
      const nothingDonePayload = {
        rfq_id: this.quoteFeedResponseEvent.quote_id,
        rfq_cancel_message: declineMsg,
        rfq_account: "",
        rfq_quote_request_id: this.quoteFeedResponseEvent.quote_request_id
      }
      this.nothingDoneRfq(nothingDonePayload)
        .then(() => {
          this.isQuoteRequested = false
          this.resetPricingStates()
          this.eventSourceQuoteFeed.close()
        })
        .catch((error) => {
          console.log(error)
          this.nothingDoneRequestFail = true
        })
        .finally(() => {
          this.loadingDecline = false
        })
    },
    acceptQuote () {
      this.acceptQuoteRequestFail = false
      this.loadingAcceptQuote = true
      const neworderpayload = this.generateNewOrderPayload(this.getBlotter)
      this.newOrderRfq(neworderpayload)
        .then(() => {
          this.listenReceiptFeed()
        })
        .catch((error) => {
          console.log(error)
          this.acceptQuoteRequestFail = true
        })
    },
    // TODO: receive this.-data by parameter
    // TODO: move this function to RfqUtils.js file
    generateNewOrderPayload (product) { 
      let quoteprice = this.quoteFeedResponseEvent.offer
      const side = this.newRfqPayload.rfq_side
      if (side === 1) {
        quoteprice = this.quoteFeedResponseEvent.bid
      }
      const neworderpayload = {
        rfq_id: this.quoteFeedResponseEvent.quote_id,
        rfq_quote_req_id: this.quoteFeedResponseEvent.quote_request_id,
        rfq_quote_price: quoteprice,
        rfq_symbol: this.newRfqPayload.rfq_symbol,
        rfq_taker_id: this.newRfqPayload.rfq_taker_id,
        rfq_taker_name: this.newRfqPayload.rfq_taker_name,
        rfq_orderqty: this.newRfqPayload.rfq_orderqty,
        rfq_orderqty_ccy: this.newRfqPayload.rfq_orderqty_ccy,
        rfq_settdate_ccy1: this.newRfqPayload.rfq_settdate_ccy1,
        rfq_settdate_ccy2: this.newRfqPayload.rfq_settdate_ccy2,
        rfq_side: side,
        rfq_account: this.newRfqPayload.rfq_taker_id,
        rfq_product: product,
        rfq_maker_id: this.newRfqPayload.rfq_maker_id,
        rfq_maker_name: this.newRfqPayload.rfq_maker_name,
        rfq_text: this.quoteFeedResponseEvent.text
      };
      return neworderpayload
    },
    listenReceiptFeed () {
      const receiptId = this.quoteFeedResponseEvent.quote_request_id;
      const url = `${this.apiRfqHost}/apiv1.1/octax/receipt/feed`;
      const eventSource = new EventSourcePolyfill(url, {
        headers: { SECRETKEY: this.apiSecretKey },
      })
      eventSource.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        const receipt = data.filter(
          (receipt) => receipt.transaction_id === receiptId
        )[0]
        if (receipt) {
          this.$refs.rfqReceipt.setReceipt(receipt);
          this.resetPricingStates()
          this.resetFormFields()
          this.stepper = 2
          eventSource.close();
        }
        // TODO: add a call to ack receipt here
      })
    },
    resetFormFields () {
      this.isQuoteRequested = false
      this.$refs.rfqFormFXSpot.resetFormFields()
    },
    resetPricingStates () {
      this.buyCcy = ""
      this.sellCcy = ""
      this.quoteLastUpdate = ""
      this.loadingAcceptQuote = false
      this.$refs.rfqPrices.resetPricingStates()
    },
    setErrorAnimation () {
      this.$refs.rfqPrices.setErrorAnimation(this.newRfqPayload.rfq_side)
    },
    setTimeoutAnimation () {
      this.$refs.rfqPrices.setTimeoutAnimation(this.newRfqPayload.rfq_side)
    },
    errorAnimationTimeoutCallback () {
      this.isQuoteRequested = false;
      this.resetPricingStates();
      this.eventSourceQuoteFeed.close()
    },
    setBuyData (data) { // TODO: rename to setBuyPriceStates
      this.$refs.rfqPrices.setBuyData(data)
    },
    setSellData (data) { // TODO: rename to setSellPriceStates
      this.$refs.rfqPrices.setSellData(data)
    },
    setFwdBuyData (bid_fwd_points, bid_spot) {
      this.$refs.rfqPrices.setFwdBuyData(bid_fwd_points, bid_spot)
    },
    setFwdSellData(offer_fwd_points, offer_spot) {
      this.$refs.rfqPrices.setFwdSellData(offer_fwd_points, offer_spot)
    },
    newRfqCallback () {
      this.stepper = 1;
      setTimeout(() => {
        this.resetFormFields()
      }, 1000);
    },
    emitDisableActionsEvent () {
      this.$emit("disableActions")
    },
    emitEnableActionsEvent () {
      this.$emit("enableActions")
    }
  }
}
</script>