<template>
  <div>
    <div class="d-flex justify-center">
      <v-card 
        class="rounded-lg pa-4 gray-border" 
        :max-width="700" 
        :min-width="700"
        :elevation="20"
      >
        <div class="text-h2 mb-4 py-4 text-center accent--text">
          Safra FX
        </div>

        <template v-if="product !== undefined">
          <v-divider></v-divider>

          <div class="d-flex py-4 align-center">
            <div>
              <p 
                style="
                  color: hsla(0, 0%, 100%, 0.7);
                  margin-bottom: 3px;
                  font-size: 12px;
                "
              >
                Product:
              </p>

              <v-btn-toggle
                dense
                :mandatory="!isReceipt"
                borderless
                v-model="product"
                color="accent"
              >
                <v-btn 
                  value="fxspot" 
                  :disabled="allowedProducts[0]['disabled'] || disableActions || isReceipt" 
                  @click="changeProduct('fxspot')"
                >
                  FXSpot
                </v-btn>

                <v-btn 
                  value="fxndf" 
                  :disabled="allowedProducts[1]['disabled'] || disableActions || isReceipt" 
                  @click="changeProduct('fxndf')"
                >
                  FXNDF
                </v-btn>
              </v-btn-toggle>
            </div>

            <!-- <v-divider vertical inset class="mx-3"></v-divider> -->

            <v-spacer></v-spacer>

            <div class="mr-4 d-flex align-center">
              <v-icon class="mr-2">
                mdi-account-circle-outline
              </v-icon>
              {{ getAuth ? getAuth.name : "" }}
            </div>

            <v-btn icon @click="logout()" :disabled="disableActions">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </div>

          <v-divider></v-divider>

          <rfq-Tool
            :comboBoxItems="comboBoxItems"
            @disableActions="disableActions=true"
            @enableActions="disableActions=false"
            @isReceipt="isReceipt=true;isForm=false"
            @isForm="isForm=true;isReceipt=false"
          />
        </template>

        <template v-else>
          <div class="text-center py-16 my-16">
            <v-progress-circular
              indeterminate
              :size="100"
              :width="5"
              color="accent"
            >
            </v-progress-circular>
          </div>
        </template>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia"
import { useDisplayStore, useAuthStore } from "./../store/index"
import RfqTool from "./../components/RfqTool.vue"

export default {
  components: {
    RfqTool
  },
  data () {
    return {
      tab: null,
      comboBoxItems: [
        {
          text: "Banco Safra",
          value: "safra"
        }
      ],
      portalDialog: false,
      products: [
        { name: "fxspot", disabled: true },
        { name: "fxndf", disabled: true }
      ],
      product: undefined,
      disableActions: false,
      isReceipt: false,
      isForm: false
    }
  },
  watch: {
    blotter: {
      handler (newValue, oldValue) {
        this.product = newValue
      }
    }
  },
  computed: {
    ...mapState(useDisplayStore, [
      "isMobile"
    ]),
    ...mapState(useAuthStore, [
      "getBlotter",
      "getAuth"
    ]),
    ...mapWritableState(useAuthStore, [
      "blotter"
    ]),
    allowedProducts () {
      if (!this.getAuth) {
        return this.products
      }
      const userAllowedProdutcs = this.getAuth.allowed_products
      for (let i = 0; i < this.products.length; i++) {
        const productName = this.products[i]["name"]
        if (userAllowedProdutcs[productName]) {
          this.products[i]["disabled"] = false
        }
      }
      return this.products
    }
  },
  mounted () {
    this.loginHandshake(this.$auth.user.sub)
    // this.portalDialog = true
  },
  methods: {
    ...mapActions(useAuthStore, {
      loginHandshake: "loginHandshake",
      removeAuthInfo: "removeAuthInfo",
      setFXProduct: "setFXProduct"
    }),
    closePortalDialog () {
      this.portalDialog = false
    },
    changeProduct (product) {
      this.setFXProduct(product)
    },
    logout() {
      this.$auth.logout({
        logoutParams: {
          returnTo: window.location.origin
        }
      });
      this.removeAuthInfo()
    },
  }
}
</script>

<style scoped>
/* ::v-deep .v-tab:before, span.v-ripple__container, span.v-ripple__container > span {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
}  */
/* ::v-deep .v-ripple__container {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
} */
/* ::v-deep .v-tab.v-tab--active > span {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
} */
</style>
