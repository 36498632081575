<template>
  <div>
    <v-card-text>
      <div style="display: flex; flex-direction: column">
        <v-simple-table dense>
          <tr>
            <td class="header-color cell_title">Product:</td>
            <td style="color: white">{{ transactionDetails.product }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">RFQ ID:</td>
            <td style="color: white">{{ transactionDetails.transaction_id | checkEmpty }}</td>
          </tr>
          <tr v-if="!isRfqCloud">
            <td class="header-color cell_title">Counterparty alias:</td>
            <td style="color: white">{{ transactionDetails.client_id | checkEmpty }}</td>
          </tr>
          <tr v-if="!isRfqCloud">
            <td class="header-color cell_title">Counterparty alias 2:</td>
            <td style="color: white">{{ transactionDetails.client_id | checkEmpty }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Counterparty CNPJ:</td>
            <td style="color: white;">{{ transactionDetails.client_cnpj | formatCNPJ }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Amount CCY:</td>
            <td style="color: white">{{ transactionDetails.amount_ccy | padEnd2 | formatPrice }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">CCY:</td>
            <td style="color: white">{{ transactionDetails.currency_code_iso | checkEmpty }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Start Date:</td>
            <td style="color: white">{{ transactionDetails.start_date | checkEmpty }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Maturity:</td>
            <td style="color: white">{{ transactionDetails.maturity | checkEmpty }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Fixing Date:</td>
            <td style="color: white">{{ transactionDetails.fixing_date | checkEmpty }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Fixing Convention:</td>
            <td style="color: white">{{ transactionDetails.fixing_convention | checkEmpty }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Side:</td>
            <td style="color: white;">{{ getSide }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Spot CCY Cost Rate:</td>
            <td style="color: white">{{ transactionDetails.spot_ccy_cost_rate | checkEmpty }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Transaction Value BRL:</td>
            <td style="color: white">{{ transactionDetails.transaction_value_brl | checkEmpty }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">FWD CCY Client Rate:</td>
            <td style="color: white">{{ transactionDetails.fwd_ccy_client_rate | checkEmpty }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">FWD CCY Cost Rate:</td>
            <td style="color: white">{{ transactionDetails.fwd_ccy_cost_rate | checkEmpty }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">FWD BRL Amount:</td>
            <td style="color: white">{{ transactionDetails.fwd_brl_amount | checkEmpty }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Yield CCY Client Rate:</td>
            <td style="color: white">{{ transactionDetails.yield_ccy_client_rate | checkEmpty }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Yield CCY Cost Rate:</td>
            <td style="color: white">{{ transactionDetails.yield_ccy_cost_rate | checkEmpty }}</td>
          </tr>
          <!-- <tr>
            <td class="header-color cell_title">Yield CCY Convention:</td>
            <td style="color: white">{{ transactionDetails.yield_ccy_convention | checkEmpty }}</td>
          </tr> -->
          <tr>
            <td class="header-color cell_title">Yield CCY BRL Client Rate:</td>
            <td style="color: white">{{ transactionDetails.yield_brl_client_rate | checkEmpty }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Yield CCY BRL Cost Rate:</td>
            <td style="color: white">{{ transactionDetails.yield_brl_cost_rate | checkEmpty }}</td>
          </tr>
          <!-- <tr>
            <td class="header-color cell_title">Yield CCY BRL Convention:</td>
            <td style="color: white">{{ transactionDetails.yield_brl_convention | checkEmpty }}</td>
          </tr> -->
        </v-simple-table>
      </div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: {
    // transactionDetails: {
    //   type: Object,
    //   required: true
    // },
    showTir: {
      type: Boolean,
      default: false
    },
    isRfqCloud: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    formatPrice: (price) => {
      if (!isNaN(price)) {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
      return price
    },
    checkEmpty: (value) => {
      if (value) {
        return value;
      }
      return "-";
    },
    getSide: value => {
      const buy = "A"
      const sell = "P"
      if (this.isRfqCloud && value === sell) {
        return "Sell"
      }
      if (this.isRfqCloud && value === buy) {
        return "Buy"
      }
      if (value === sell) {
        return "P (Sell)"
      }
      return "A (Buy)"
    },
    formatCNPJ: (cnpj) => {
      if (cnpj) {
        cnpj = cnpj.toString().split("");
        cnpj.splice(2, 0, ".");
        cnpj.splice(6, 0, ".");
        cnpj.splice(10, 0, "/");
        cnpj.splice(15, 0, "-");

        return cnpj.join("");
      }
    },
    padEnd2: (price) => {
      if (!isNaN(price)) {
        price = price.toString()
        let result = price.split(".")
        if (result[1]) {
          result[1] = result[1].padEnd(2, "0")
          return result[0].concat(".").concat(result[1])
        }
        const decimalPlaces = "".padEnd(2, "0")
        return price.concat(".").concat(decimalPlaces)
      }
      return price
    },
    padEnd4: (price) => {
      if (!isNaN(price)) {
        price = price.toString()
        let result = price.split(".")
        if (result[1]) {
          result[1] = result[1].padEnd(4, "0")
          return result[0].concat(".").concat(result[1])
        }
        const decimalPlaces = "".padEnd(4, "0")
        return price.concat(".").concat(decimalPlaces)
      }
      return price
    },
    padEndJpy: (price) => {
      if (!isNaN(price)) {
        price = price.toString()
        let result = price.split(".")
        if (result[1]) {
          result[1] = result[1].padEnd(6, "0")
          return result[0].concat(".").concat(result[1])
        }
        const decimalPlaces = "".padEnd(6, "0")
        return price.concat(".").concat(decimalPlaces)
      }
      return price
    }
  },
  data () {
    return {
      transactionDetails: {
        product: undefined,
        transaction_id: undefined,
        client_cnpj: undefined,
        client_id: undefined,
        side: undefined,
        currency_code_iso: undefined,
        amount_ccy: undefined,
        client_id2: undefined,
        start_date: undefined,
        maturity: undefined,
        fixing_date: undefined,
        fixing_convention: undefined,
        spot_ccy_cost_rate: undefined,
        transaction_value_brl: undefined,
        fwd_ccy_client_rate: undefined,
        fwd_ccy_cost_rate: undefined,
        fwd_brl_amount: undefined,
        yield_ccy_client_rate: undefined,
        yield_ccy_cost_rate: undefined,
        yield_ccy_convention: undefined,
        yield_brl_client_rate: undefined,
        yield_brl_cost_rate: undefined,
        yield_brl_convention: undefined,
      }
    }
  },
  computed: {
    getSide () {
      const buy = "A"
      const sell = "P"
      if (this.isRfqCloud && this.transactionDetails.side === sell) {
        return "Sell"
      }
      if (this.isRfqCloud && this.transactionDetails.side === buy) {
        return "Buy"
      }
      if (this.transactionDetails.side === sell) {
        return "P (Sell)"
      }
      return "A (Buy)"
    }
  },
  methods: {
    setReceipt (data) {
      // this.transactionDetails = JSON.parse(
      //   JSON.stringify(this.transactionDetailsTemplateNdf)
      // )
      this.transactionDetails.product = data.product
      this.transactionDetails.transaction_id = data.transaction_id
      this.transactionDetails.client_cnpj = data.client_cnpj
      this.transactionDetails.client_id = data.client_id
      this.transactionDetails.side = data.side
      this.transactionDetails.currency_code_iso = data.currency_code_iso
      this.transactionDetails.amount_ccy = data.amount_ccy
      // this.transactionDetails.amount_brl = data.amount_brl;
      this.transactionDetails.client_id2 = data.client_id2
      this.transactionDetails.start_date = data.start_date
      this.transactionDetails.maturity = data.maturity
      this.transactionDetails.fixing_date = data.fixing_date
      this.transactionDetails.fixing_convention = data.fixing_convention
      this.transactionDetails.spot_ccy_cost_rate = data.spot_ccy_cost_rate
      this.transactionDetails.transaction_value_brl = data.transaction_value_brl
      this.transactionDetails.fwd_ccy_client_rate = data.fwd_ccy_client_rate
      this.transactionDetails.fwd_ccy_cost_rate = data.fwd_ccy_cost_rate
      this.transactionDetails.fwd_brl_amount = data.fwd_brl_amount
      this.transactionDetails.yield_ccy_client_rate = data.yield_ccy_client_rate
      this.transactionDetails.yield_ccy_cost_rate = data.yield_ccy_cost_rate
      this.transactionDetails.yield_ccy_convention = data.yield_ccy_convention
      this.transactionDetails.yield_brl_client_rate = data.yield_brl_client_rate
      this.transactionDetails.yield_brl_cost_rate = data.yield_brl_cost_rate
      this.transactionDetails.yield_brl_convention = data.yield_brl_convention
    }
  }
}
</script>

<style scoped>
.v-label {
  font-size: 14px;
}
.cell_title {
  font-weight: 700;
  padding-right: 10px;
}
.custom-cell-value {
  color: #ff9800;
}
.title-table-rfq {
  color: #ff9800;
  font-size: 16px;
}
.header-color {
  color: #ffffffb3;
}
</style>