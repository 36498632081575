<template>
  <div>
    <div class="d-flex justify-center align-center" v-if="false">
      <v-icon
        class="attention--text mr-2"
      >
        mdi-alert-circle-outline
      </v-icon>

      <span class="attention--text mr-2 text-body-2">
        Fail to fetch data
      </span>
    </div>

    <v-dialog
      v-model="fetchFailDialog"
      persistent
      width="500"
      content-class="gray-border rounded-xl elevation-24"
    >
      <v-card height="200">
        <div class="d-flex justify-center align-center pa-5">
          <v-icon
            class="attention--text mr-2"
          >
            mdi-alert-circle-outline
          </v-icon>

          <v-card-text>
            <div class="white--text mr-2 text-body-2">
              Fail to fetch data from OctaX backend services, this page will reload in a few seconds. 
              Should this problem persist, please contact Octaplus support.
            </div>
          </v-card-text>
        </div>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            text
            rounded
            @click="fetchFailDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { mapWritableState, mapState } from "pinia";
// import { useNetworkStore } from "./../store/index";

export default {
  props: {
    error: {
      required: true
    }
  },
  data () {
    return {
      fetchFailDialog: false
    }
  },
  computed: {
    // ...mapWritableState(useNetworkStore, [
    //   "fetchFailDialog"
    // ]),
    // ...mapState(useNetworkStore, [
    //   "isFetchFailDialogActive"
    // ])
  },
  watch: {
    error: {
      handler (newValue) { 
        if (newValue) {
          this.fetchFailDialog = newValue
          setTimeout(() => {
            if (this.fetchFailDialog) {
              window.location.reload();
            }
          }, 10000)
        }
      }
    }
  }
}
</script>
