<template>
  <div 
    class="d-flex ma-auto text-center py-16 my-16" 
    style="height: 100%; width: 100%;"
  >
    <v-progress-circular
      indeterminate
      :size="100"
      :width="5"
      color="accent"
      class="ma-auto"
    >
    </v-progress-circular>
  </div>
</template>

<script>
import { mapState } from "pinia"
import { useDisplayStore } from "./../store/index"

export default {
  watch: {
    isLoadingAuth0: {
      handler (newValue, oldValue) {
        if (!newValue && !this.$auth.isAuthenticated) {
          this.login()
        }
        if (!newValue && this.$auth.isAuthenticated) {
          this.redirectToBlotter()
        }
      }
    }
  },
  computed: {
    ...mapState(useDisplayStore, ["isMobile"]),
    isLoadingAuth0 () {
      return this.$auth.loading
    }
  },
  methods: {
    // Log the user in
    redirectToBlotter () {
      this.$router.push("/blotter")
    },
    login() {
      this.$auth.loginWithRedirect({
        appState: {
          targetPath: "/blotter"
        }
      });
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        logoutParams: {
          returnTo: window.location.origin
        }
      });
    }
  }
}
</script>

<style>
</style>