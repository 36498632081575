<template>
  <v-app>
    <v-main>
      <v-container
        :fluid="isUserAuthenticated" 
        class="pa-4 d-flex ma-auto align-center justify-center"
        style="height: 100%;"
      >
        <router-view/>
      </v-container>
    </v-main>

    <v-footer 
      v-if="true ? false : showLayoutElements(routePath)"
      app 
      padless 
      dense 
      height="30"
      style="border-top: 1px solid #424242;"
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-col
          class="primary py-1 text-center white--text"
          cols="12"
        >
          <strong>Safra FX</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapWritableState, mapActions } from "pinia"
import { useAuthStore, useDisplayStore } from "./store/index"

export default {
  watch: {
    breakpoint: {
      handler (newValue) {
        this.setBreakpoint(newValue)
      }
    }
  },
  mounted () {
    this.setBreakpoint(this.breakpoint)
    this.setBlotterState()
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState(useAuthStore, [
      "isUserAuthenticated",
    ]),
    ...mapState(useDisplayStore, [
      "isMobile",
      "showLayoutElements",
      "isSmall",
      "isXSmall" 
    ]),
    breakpoint () {
      return this.$vuetify.breakpoint.name
    },
    routePath () {
      return this.$route.path
    },
    ...mapWritableState(useDisplayStore, {
      mobileState: "mobile",
      breakpointState: "breakpoint",
      windowHeightState: "windowHeight",
    }),
  },
  methods: {
    ...mapActions(useAuthStore, {
      setBlotterState: "setBlotter",
    }),
    setBreakpoint (value) {
      this.breakpointState = value
      this.mobileState = this.isSmall || this.isXSmall
    }
  }
};
</script>
