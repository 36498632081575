<template>
  <v-card 
    :rounded="rounded"
    :disabled="!isQuoteRequested || loadingQuote"
  >
    <div class="mt-4" style="background: #343333;">
      <div class="py-4 px-6">
        <!-- LAST UPDATE -->
        <div class="d-flex justify-center">
          <p style="font-size: 14px">
            Last update: {{ quoteLastUpdate }}
          </p>
        </div>

        <!-- CARDS BUY/SELL -->
        <div class="d-flex justify-center">
          <v-card
            :min-height="isFxndf ? 160 : 115"
            :max-height="isFxndf ? 160 : 115"
            :hover="isBuyRequest && !loadingAcceptQuote && !loadingDecline"
            :color="cardBuyColor"
            :disabled="isSellRequest || loadingAcceptQuote || loadingDecline || buyReject !== undefined || buyNothdone !== undefined || sellReject !== undefined || sellNothdone !== undefined"
            :class="{
              'mr-2': true,
              'cursor-pointer': isBuyRequest
            }"
            :ripple="isBuyRequest"
            @click="lockPriceBuy = !lockPriceBuy"
            id="card-buy"
            :rounded="'lg'"
          >
            <div
              class="d-flex flex-wrap align-center"
              :style="{
                height: isFxndf ? '160px' : '115px'
              }"
              :class="{
                'price-card-column': true,
                'lock-price-border': lockPriceBuy,
                'unlock-price-border': !lockPriceBuy,
                'reject-price-border': buyReject !== undefined,
                'nothdone-price-border': buyNothdone !== undefined,
                'no-price-border': !isBuyRequest
              }"
            >
              <span class="price-box-title"> Buy {{ buyCcy }}</span>
              <div class="d-flex" style="align-items: baseline">
                <span style="font-size: 18px"> {{ buy }} </span>
                <span class="price-box-bps"> {{ bipsBuy }} </span>
                <span style="font-size: 22px; font-weight: bold"> {{ buyReject }} </span>
                <span style="font-size: 22px; font-weight: bold"> {{ buyNothdone }} </span>
              </div>
              <template v-if="isFxndf">
                <div class="d-flex" style="align-items: baseline">
                  <span style="font-size: 11px"> Spot Rate: {{ spotRateBuy }} </span>
                </div>
                <div class="d-flex" style="align-items: baseline">
                  <span style="font-size: 11px"> FWD Points: {{ fwdPointsBuy }} </span>
                </div>
              </template>
            </div>
          </v-card>

          <v-card
            :hover="isSellRequest && !loadingAcceptQuote && !loadingDecline"
            :color="cardSellColor" 
            :min-height="isFxndf ? 160 : 115"
            :max-height="isFxndf ? 160 : 115"
            :disabled="isBuyRequest || loadingAcceptQuote || loadingDecline || buyReject !== undefined || buyNothdone !== undefined || sellReject !== undefined || sellNothdone !== undefined"
            id="card-sell"
            :class="{ 
              'mr-2': true, 
              'cursor-pointer': isSellRequest
            }"
            :rounded="'lg'"
            :ripple="isSellRequest"
            @click="lockPriceSell = !lockPriceSell"
          >
            <div 
              class="d-flex flex-wrap align-center"
              :style="{
                height: isFxndf ? '160px' : '115px'
              }"
              :class="{
                'price-card-column': true,
                'lock-price-border': lockPriceSell,
                'unlock-price-border': !lockPriceSell,
                'reject-price-border': sellReject !== undefined,
                'nothdone-price-border': sellNothdone !== undefined,
                'no-price-border': !isSellRequest
              }"
            >
              <span class="price-box-title"> Sell {{ sellCcy }} </span>
              <div class="d-flex" style="align-items: baseline">
                <span style="font-size: 18px"> {{ sell }} </span>
                <span class="price-box-bps"> {{ bipsSell }} </span>
                <span style="font-size: 22px; font-weight: bold"> {{ sellReject }} </span>
                <span style="font-size: 22px; font-weight: bold"> {{ sellNothdone }} </span>
              </div>
              <template v-if="isFxndf">
                <div class="d-flex" style="align-items: baseline">
                  <span style="font-size: 11px"> Spot Rate: {{ spotRateSell }} </span>
                </div>
                <div class="d-flex" style="align-items: baseline">
                  <span style="font-size: 11px"> FWD Points: {{ fwdPointsSell }} </span>
                </div>
              </template>
            </div>
          </v-card>
        </div>

        <!-- BUTTONS DECLINE/ACCEPT -->
        <div
          class="d-flex"
          style="justify-content: space-evenly; margin-top: 30px"
        >
          <div>
            <v-btn
              :elevation="0"
              :disabled="!isQuoteRequested || loadingAcceptQuote || buyReject !== undefined || buyNothdone !== undefined || sellReject !== undefined || sellNothdone !== undefined"
              @click="declineQuote('Taker declined manually quoted price')"
              color="#616161"
              style="font-size: 14px"
              small
              rounded
              :loading="loadingDecline"
            >
              DECLINE
            </v-btn>
          </div>

          <div>
            <v-btn
              :elevation="0"
              :disabled="!isQuoteRequested || loadingDecline || (!lockPriceBuy && !lockPriceSell) || buyReject !== undefined || buyNothdone !== undefined || sellReject !== undefined || sellNothdone !== undefined"
              color="accent" 
              small
              rounded
              class="black--text"
              style="font-size: 14px;"
              :loading="loadingAcceptQuote"
              @click="acceptQuote();"  
            >
              ACCEPT
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    isQuoteRequested: { // TODO: rename to disableInteraction or equivalent
      type: Boolean,
      required: false,
      default: false
    },
    loadingQuote: { // TODO: remove
      type: Boolean,
      required: false,
      default: false
    },
    quoteLastUpdate: {
      type: String,
      required: false,
      default: "-"
    },
    buyCcy: {
      type: String,
      required: false,
      default: ""
    },
    sellCcy: {
      type: String,
      required: false,
      default: ""
    },
    loadingAcceptQuote: {
      type: Boolean,
      required: false,
      default: false
    },
    loadingDecline: {
      type: Boolean,
      required: false,
      default: false
    },
    rounded: {
      type: String,
      required: false,
      default: "xl"
    },
    isFxndf: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      // 
      buy: "",
      bipsBuy: "-",
      sell: "",
      bipsSell: "-",
      isSellRequest: false,
      isBuyRequest: false,
      // 
      cardSellColor: "#100e43",
      lockPriceSell: false,
      sellReject: undefined,
      sellNothdone: undefined,
      // 
      cardBuyColor: "#100e43",
      lockPriceBuy: false,
      buyReject: undefined,
      buyNothdone: undefined,
      // 
      blueColor: "#100e43",
      greenColor: "#00a400",
      redColor: "red",
      orangeColor: "#ff9800",
      fwdPointsBuy: "",
      spotRateBuy: "",
      fwdPointsSell: "",
      spotRateSell: ""
    }
  },
  methods: {
    acceptQuote() {
      // this.loadingAcceptQuote = true
      // const neworderpayload = this.generateNewOrderPayload()
      this.$emit("acceptQuote")
      // this.newOrderRfq(neworderpayload)
      //   .then(() => {
      //     this.listenReceiptFeed();
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
    resetPricingStates () {
      this.cardBuyColor = this.blueColor
      this.cardSellColor = this.blueColor
      this.buy = ""
      this.bipsBuy = "-"
      this.buyReject = undefined
      this.buyNothdone = undefined
      this.isBuyRequest = false
      this.sell = ""
      this.bipsSell = "-"
      this.sellReject = undefined
      this.sellNothdone = undefined
      this.isSellRequest = false
      this.lockPriceSell = false
      this.lockPriceBuy = false
    },
    declineQuote (msg) {
      this.$emit("declineQuote", msg)
    },
    setErrorAnimation (data) {
      const isBuyRequest = data === 1 ? true : false
      let cardClass = isBuyRequest ? "card-buy" : "card-sell";
      let priceCard = document.getElementById(cardClass);
      priceCard.classList.add("bounce");
      if (isBuyRequest) {
        this.cardBuyColor = this.redColor;
        this.buy = ""
        this.bipsBuy = "";
        this.buyReject = "REJECTED";
      } else {
        this.cardSellColor = this.redColor;
        this.sell = ""
        this.bipsSell = "";
        this.sellReject = "REJECTED";
      }
      setTimeout(() => {
        priceCard.classList.remove("bounce");
        this.$emit("errorAnimationTimeout")
        // this.isQuoteRequested = false;
        this.resetPricingStates();
        // this.eventSource.close();
      }, 1200);
    },
    setTimeoutAnimation (data) {
      const isBuyRequest = data === 1 ? true : false
      let cardClass = isBuyRequest ? "card-buy" : "card-sell";
      let priceCard = document.getElementById(cardClass);
      priceCard.classList.add("bounce-nothdone");
      if (isBuyRequest) {
        this.cardBuyColor = this.orangeColor;
        this.buy = ""
        this.bipsBuy = "";
        this.buyNothdone = "TIMEOUT";
      } else {
        this.cardSellColor = this.orangeColor;
        this.sell = ""
        this.bipsSell = "";
        this.sellNothdone = "TIMEOUT";
      }
      setTimeout(() => {
        priceCard.classList.remove("bounce-nothdone");
        this.$emit("errorAnimationTimeout")
        // this.isQuoteRequested = false;
        this.resetPricingStates();
        // this.eventSource.close();
      }, 1200);
    },
    setBuyData(data, ccy) {
      data = this.getPriceAndBips(data, ccy)
      this.isBuyRequest = true;
      this.buy = data.price;
      this.bipsBuy = data.bips;
      // this.buyCcy = this.selectedParity;
      // this.sellCcy = "";
      this.sell = "";
      this.bipsSell = "-";
      this.cardBuyColor = this.greenColor;
      this.cardSellColor = this.blueColor;
    },
    setFwdBuyData(dataFwd, dataSpot){
      this.fwdPointsBuy = dataFwd;
      this.fwdPointsSell = "";
      this.spotRateBuy = dataSpot;
      this.spotRateSell = "";
    },
    setSellData(data, ccy) {
      data = this.getPriceAndBips(data, ccy)
      this.isSellRequest = true;
      this.sell = data.price;
      this.bipsSell = data.bips;
      // this.sellCcy = this.selectedParity;
      // this.buyCcy = "";
      this.buy = "";
      this.bipsBuy = "-";
      this.cardSellColor = this.greenColor;
      this.cardBuyColor = this.blueColor;
    },
    setFwdSellData(dataFwd, dataSpot){
      this.fwdPointsSell = dataFwd;
      this.fwdPointsBuy = "";
      this.spotRateSell = dataSpot;
      this.spotRateBuy = "";
    },
    getPriceAndBips(price, ccy) {
      const jpyCcy = "JPY";
      let sliceParameter = -2;
      let digits = 6;
      if (ccy === jpyCcy) {  
        digits = 8;
        sliceParameter = -4;
      }
      price = price.toString();
      if (price.length === 1) {
        price = `${price}.`;
      }
      price = price.padEnd(digits, "0");
      const priceSlited = price.split(".");
      const integer = priceSlited[0];
      let decimals = priceSlited[1];
      const bips = decimals.slice(sliceParameter);
      decimals = decimals.slice(0, sliceParameter);
      price = `${integer}.${decimals}`;
      return { price: price, bips: bips };
    },
  }
}
</script>

<style>
.price-box-title {
  font-size: 16px;
}
.price-box-bps {
  font-weight: bold;
  font-size: 34px;
}
.price-card-column {
  flex-direction: column;
  justify-content: center;
  width: 160px;
  padding: 15px 0px;
}
::v-deep .theme--dark.v-list-item .v-list-item__mask {
  color: unset !important;
  background: none !important;
}
.bounce {
  outline: 0;
  border-color: red !important;
  animation-name: bounce;
  animation-duration: 0.7s;
  animation-delay: 0.15s;
}
.bounce-nothdone {
  outline: 0;
  border-color: #ff9800 !important;
  animation-name: bounce-nothdone;
  animation-duration: 0.7s;
  animation-delay: 0.15s;
}

/* This approximates the ease-in-out-bounce animation from easings.net, which would require a plug-in to use*/
@keyframes bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}
@keyframes bounce-nothdone {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}

.lock-price-border {
  border: 5px solid white;
  border-radius: 8px;
}
.unlock-price-border {
  border: 5px solid #00a400;
  border-radius: 8px;
}
.no-price-border {
  border: 5px solid #100e43;
  border-radius: 8px;
}
.reject-price-border {
  border: 5px solid #f44336;
  border-radius: 8px;
}
.nothdone-price-border {
  border: 5px solid #ff9800;
  border-radius: 8px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>