import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import BlotterView from '../views/BlotterView.vue'
import { authGuard } from "./../auth/authGuard";

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: "/",
    name: "login",
    component: LoginView,
    meta: {
      public: true
    }
  },
  {
    path: "/blotter",
    name: "blotter",
    component: BlotterView,
    beforeEnter: authGuard,
    meta: {
      public: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// function handleRedirect (to, next, isPublicRoute, authenticated) {
//   // store.commit("setIsNewUserRoute", to.name === "newUser")
//   // store.commit("setIsLoginRoute", to.name === "login")
//   // const authenticated = store.getters.authenticated
//   const onlyLoggedOutRoute = to.matched.some(record => record.meta.onlyLoggedOutRoute)
//   // no-logged try to access private route
//   if (!isPublicRoute && !authenticated) {
//     // store.commit("setIsLoginRoute", true)
//     return next("/")
//   }
//   // logged try to access public route
//   if (authenticated && onlyLoggedOutRoute) {
//     // store.commit("setIsNewUserRoute", false)
//     // store.commit("setIsLoginRoute", false)
//     return next("/portal")
//   }
//   return next()
// }

// router.beforeEach((to, from, next) => {
//   const authStore = useAuthStore()
//   let authenticated = this.$auth.isAuthenticated
//   const isPublicRoute = to.matched.some(record => record.meta.public)
//   // validation for user refresh page (F5) cenario
//   if (authenticated === undefined && !isPublicRoute) {
//     authStore.setAuthenticated()
//     authStore.setUser()
//     authStore.setBlotter()
//     authenticated = this.$auth.isAuthenticated
//     if (authenticated) {
//       return handleRedirect(to, next, isPublicRoute, authenticated)
//     }
//     return next("/") // login

    // return store.dispatch("checkTokenValidity")
    //   .then((response) => {
    //     if (response) {
    //       store.commit("setAuthenticated", true)
    //       return handleRedirect(to, next, isPublicRoute, authenticated)
    //     }
    //   })
    //   .catch((exception) => {
    //     const response = exception.response
    //     const statusCode = response.status
    //     if (statusCode === 401) {
    //       store.commit("setAuthenticated", false)
    //       return next("/") // login
    //     }
    //   })
//   }
//   return handleRedirect(to, next, isPublicRoute, authenticated)
// })

export default router
