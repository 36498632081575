<template>
  <div>
    <div
      class="d-flex flex-column rounded-xl mt-5"
      style="border: 1px solid dimgray;"
    >
      <div class="text-subtitle" style="margin: 16px 16px 0px 16px">
        {{ isRfqCloud ? 'Transaction details' : 'Receipt' }}
      </div>

      <slot name="content"> </slot>

      <!-- <spot-rfq-tool-receipt 
        :transactionDetails="transactionDetails"
      /> -->
    </div>

    <div class="mt-5 d-flex">
      <v-spacer />

      <v-btn
        :elevation="0"
        color="accent"
        small
        rounded
        class="black--text mr-5"
        style="font-size: 14px"
        @click="newRfq()"
      >
        NEW RFQ
      </v-btn>

      <v-btn
        :elevation="0"
        color="#616161"
        small
        class="mr-5"
        style="font-size: 14px"
        disabled
        rounded
      >
        DOWNLOAD
      </v-btn>

      <v-spacer />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isRfqCloud: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    newRfq () {
      this.$emit("newRfq")
    }
  }
}
</script>