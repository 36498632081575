var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"rounded":_vm.rounded,"disabled":!_vm.isQuoteRequested || _vm.loadingQuote}},[_c('div',{staticClass:"mt-4",staticStyle:{"background":"#343333"}},[_c('div',{staticClass:"py-4 px-6"},[_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v(" Last update: "+_vm._s(_vm.quoteLastUpdate)+" ")])]),_c('div',{staticClass:"d-flex justify-center"},[_c('v-card',{class:{
            'mr-2': true,
            'cursor-pointer': _vm.isBuyRequest
          },attrs:{"min-height":_vm.isFxndf ? 160 : 115,"max-height":_vm.isFxndf ? 160 : 115,"hover":_vm.isBuyRequest && !_vm.loadingAcceptQuote && !_vm.loadingDecline,"color":_vm.cardBuyColor,"disabled":_vm.isSellRequest || _vm.loadingAcceptQuote || _vm.loadingDecline || _vm.buyReject !== undefined || _vm.buyNothdone !== undefined || _vm.sellReject !== undefined || _vm.sellNothdone !== undefined,"ripple":_vm.isBuyRequest,"id":"card-buy","rounded":'lg'},on:{"click":function($event){_vm.lockPriceBuy = !_vm.lockPriceBuy}}},[_c('div',{staticClass:"d-flex flex-wrap align-center",class:{
              'price-card-column': true,
              'lock-price-border': _vm.lockPriceBuy,
              'unlock-price-border': !_vm.lockPriceBuy,
              'reject-price-border': _vm.buyReject !== undefined,
              'nothdone-price-border': _vm.buyNothdone !== undefined,
              'no-price-border': !_vm.isBuyRequest
            },style:({
              height: _vm.isFxndf ? '160px' : '115px'
            })},[_c('span',{staticClass:"price-box-title"},[_vm._v(" Buy "+_vm._s(_vm.buyCcy))]),_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"baseline"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.buy)+" ")]),_c('span',{staticClass:"price-box-bps"},[_vm._v(" "+_vm._s(_vm.bipsBuy)+" ")]),_c('span',{staticStyle:{"font-size":"22px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.buyReject)+" ")]),_c('span',{staticStyle:{"font-size":"22px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.buyNothdone)+" ")])]),(_vm.isFxndf)?[_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"baseline"}},[_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" Spot Rate: "+_vm._s(_vm.spotRateBuy)+" ")])]),_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"baseline"}},[_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" FWD Points: "+_vm._s(_vm.fwdPointsBuy)+" ")])])]:_vm._e()],2)]),_c('v-card',{class:{ 
            'mr-2': true, 
            'cursor-pointer': _vm.isSellRequest
          },attrs:{"hover":_vm.isSellRequest && !_vm.loadingAcceptQuote && !_vm.loadingDecline,"color":_vm.cardSellColor,"min-height":_vm.isFxndf ? 160 : 115,"max-height":_vm.isFxndf ? 160 : 115,"disabled":_vm.isBuyRequest || _vm.loadingAcceptQuote || _vm.loadingDecline || _vm.buyReject !== undefined || _vm.buyNothdone !== undefined || _vm.sellReject !== undefined || _vm.sellNothdone !== undefined,"id":"card-sell","rounded":'lg',"ripple":_vm.isSellRequest},on:{"click":function($event){_vm.lockPriceSell = !_vm.lockPriceSell}}},[_c('div',{staticClass:"d-flex flex-wrap align-center",class:{
              'price-card-column': true,
              'lock-price-border': _vm.lockPriceSell,
              'unlock-price-border': !_vm.lockPriceSell,
              'reject-price-border': _vm.sellReject !== undefined,
              'nothdone-price-border': _vm.sellNothdone !== undefined,
              'no-price-border': !_vm.isSellRequest
            },style:({
              height: _vm.isFxndf ? '160px' : '115px'
            })},[_c('span',{staticClass:"price-box-title"},[_vm._v(" Sell "+_vm._s(_vm.sellCcy)+" ")]),_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"baseline"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.sell)+" ")]),_c('span',{staticClass:"price-box-bps"},[_vm._v(" "+_vm._s(_vm.bipsSell)+" ")]),_c('span',{staticStyle:{"font-size":"22px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.sellReject)+" ")]),_c('span',{staticStyle:{"font-size":"22px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.sellNothdone)+" ")])]),(_vm.isFxndf)?[_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"baseline"}},[_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" Spot Rate: "+_vm._s(_vm.spotRateSell)+" ")])]),_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"baseline"}},[_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" FWD Points: "+_vm._s(_vm.fwdPointsSell)+" ")])])]:_vm._e()],2)])],1),_c('div',{staticClass:"d-flex",staticStyle:{"justify-content":"space-evenly","margin-top":"30px"}},[_c('div',[_c('v-btn',{staticStyle:{"font-size":"14px"},attrs:{"elevation":0,"disabled":!_vm.isQuoteRequested || _vm.loadingAcceptQuote || _vm.buyReject !== undefined || _vm.buyNothdone !== undefined || _vm.sellReject !== undefined || _vm.sellNothdone !== undefined,"color":"#616161","small":"","rounded":"","loading":_vm.loadingDecline},on:{"click":function($event){return _vm.declineQuote('Taker declined manually quoted price')}}},[_vm._v(" DECLINE ")])],1),_c('div',[_c('v-btn',{staticClass:"black--text",staticStyle:{"font-size":"14px"},attrs:{"elevation":0,"disabled":!_vm.isQuoteRequested || _vm.loadingDecline || (!_vm.lockPriceBuy && !_vm.lockPriceSell) || _vm.buyReject !== undefined || _vm.buyNothdone !== undefined || _vm.sellReject !== undefined || _vm.sellNothdone !== undefined,"color":"accent","small":"","rounded":"","loading":_vm.loadingAcceptQuote},on:{"click":function($event){return _vm.acceptQuote();}}},[_vm._v(" ACCEPT ")])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }