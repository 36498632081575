<template>
  <v-sheet>
    <v-form
      v-model="valid"
      ref="form"
      :disabled="isQuoteRequested"
      class="mt-4"
    >
      <div v-if="enableBrlCcy1" class="d-flex align-center mb-3">
        <div class="mt-5" :style="{ fontSize: '12px' }">
          CCY
        </div>
        <v-switch
          v-model="brlCcy1"
          class="ml-3"
          color="accent"
          flat
          inset
          hide-details
        >
        </v-switch>
        <div class="mt-5" :style="{ fontSize: '12px' }">
          BRL
        </div>
      </div>

      <div class="d-flex mb-8">
        <div style="width: 100%">
          <p
            style="
              color: hsla(0, 0%, 100%, 0.7);
              margin-bottom: 3px;
              font-size: 12px;
            "
          >
            Parity
          </p>
          <v-btn-toggle
            style="width: 300px"
            v-model="selectedParity"
            color="accent"
            dense
            mandatory
            borderless
          >
            <v-btn :value="'USD'" :disabled="isQuoteRequested || brlCcy1">
              USDBRL
            </v-btn>
            <v-btn :value="'EUR'" :disabled="isQuoteRequested || brlCcy1">
              EURBRL
            </v-btn>
            <v-btn 
              :value="'JPY'" 
              :disabled="isQuoteRequested || brlCcy1"
              @click="isFxndf === true ? true : selectD2Parity()"
            >
              JPYBRL
            </v-btn>
          </v-btn-toggle>
        </div>

        <div class="d-flex" style="width: 100%">
          <div>
            <p
              style="
                color: hsla(0, 0%, 100%, 0.7);
                margin-bottom: 3px;
                font-size: 12px;
              "
            >
              Side
            </p>
            <v-btn-toggle
              style="width: 150px"
              v-model="clientSide"
              color="accent"
              dense
              mandatory
              borderless
            >
              <v-btn :value="1" :disabled="isQuoteRequested">
                Buy
              </v-btn>

              <v-btn :value="2" :disabled="isQuoteRequested">
                Sell
              </v-btn>
            </v-btn-toggle>
          </div>

          <template v-if="isFxndf">
            <div style="padding-top: 5px">
              <v-select
                disabled
                style="width: 150px"
                item-color="accent"
                color="accent"
                filled
                rounded
                dense
                :items="selectFixSource"
                v-model="selectFixSource[0]"
                label="Fixing Source"
                hide-details
              >
              </v-select>
            </div>
          </template>
        </div>
      </div>

      <!-- START SETTLEMENTS -->

      <!--
      <div class="d-flex" style="color: hsla(0,0%,100%,.7); font-size: 12px">
        <div style="width: 100%">
          Settlement CCY
        </div>
        <div style="width: 100%">
          Settlement BRL
        </div>
      </div>

      <div class="d-flex  mb-8">
        <div style="width: 100%">
          <div class="d-flex align-center">
            <div>
              <v-btn-toggle
                style="width: 150px;"
                v-model="dateCcy1"
                color="accent"
                dense
                mandatory
                borderless
              >
                <v-btn :value="0" :disabled="isQuoteRequested || (selectedParity === 'JPY')">
                  D0
                </v-btn>
                <v-btn :value="1" :disabled="isQuoteRequested || (selectedParity === 'JPY')">
                  D1
                </v-btn>
                <v-btn :value="2" :disabled="isQuoteRequested">
                  D2
                </v-btn>
              </v-btn-toggle>
            </div>

            <v-chip 
              color="primaryTransp"
              style="font-size: 15px"
              class="ml-4"
            >
              {{ settlementCCY1 }}
            </v-chip>
          </div>
        </div>

        <div style="width: 100%">
          <div class="d-flex align-center">
            <div>
              <v-btn-toggle
                style="width: 150px;"
                v-model="dateCcy2"
                color="accent"
                dense
                mandatory
                borderless
              >
                <v-btn :value="0" :disabled="isQuoteRequested || (selectedParity === 'JPY')">
                  D0
                </v-btn>
                <v-btn :value="1" :disabled="isQuoteRequested || (selectedParity === 'JPY')">
                  D1
                </v-btn>
                <v-btn :value="2" :disabled="isQuoteRequested">
                  D2
                </v-btn>
              </v-btn-toggle>
            </div>

            <v-chip 
              color="primaryTransp"
              style="font-size: 15px"
              class="ml-4"
            >
              {{ settlementCCY2 }}
            </v-chip>
          </div>
        </div>
      </div>
      -->

      <template v-if="isFxndf">
        <div class="d-flex mb-5" style="width: 100%">
          <div style="width: 100%">
            <v-menu
              v-model="menuCcy1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="300px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  v-model="dateCcy1"
                  color="accent"
                  label="Fixing Date (YYYY-MM-DD)"
                  style="width: 300px; margin-right: 15px"
                  append-icon="mdi-calendar-month"
                  readonly
                  required
                  filled
                  dense
                  rounded
                  @click:append="menuCcy1 = !menuCcy1"
                  :rules="[(v) => !!v || 'CCY1 Date is required']"
                >
                </v-text-field>
              </template>

              <v-date-picker
                v-model="date1"
                @change="dateCcy1 = date1"
                @input="menuCcy1 = false"
                color="accent"
                :show-current="true"
                no-title
                :allowed-dates="disableFixingDates"
              >
              </v-date-picker>
            </v-menu>
          </div>
          <div style="width: 100%">
            <v-menu
              v-model="menuCcy2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="300px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  v-model="dateCcy2"
                  color="accent"
                  label="Maturity (YYYY-MM-DD)"
                  style="width: 300px; margin-right: 15px"
                  append-icon="mdi-calendar-month"
                  readonly
                  dense
                  required
                  filled
                  rounded
                  @click:append="menuCcy2 = !menuCcy2"
                  :rules="[(v) => !!v || 'CCY2 Date is required']"
                >
                </v-text-field>
              </template>

              <v-date-picker
                v-model="date2"
                @change="dateCcy2 = date2"
                @input="menuCcy2 = false"
                color="accent"
                :show-current="true"
                no-title
                :allowed-dates="disableMaturityDates"
              >
              </v-date-picker>
            </v-menu>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="d-flex" style="color: hsla(0,0%,100%,.7); font-size: 12px">
          <div style="width: 100%">
            Settlement CCY
          </div>
          <div style="width: 100%">
            Settlement BRL
          </div>
        </div>

        <div class="d-flex  mb-8">
          <div style="width: 100%">
            <div class="d-flex align-center">
              <div>
                <v-btn-toggle
                  style="width: 150px;"
                  v-model="dateCcy1"
                  color="accent"
                  dense
                  mandatory
                  borderless
                >
                  <v-btn :value="0" :disabled="isQuoteRequested || (selectedParity === 'JPY')">
                    D0
                  </v-btn>
                  <v-btn :value="1" :disabled="isQuoteRequested || (selectedParity === 'JPY')">
                    D1
                  </v-btn>
                  <v-btn :value="2" :disabled="isQuoteRequested">
                    D2
                  </v-btn>
                </v-btn-toggle>
              </div>

              <template v-if="showSettDateChips">
                <v-chip
                  color="primaryTransp"
                  style="font-size: 15px"
                  class="ml-4"
                >
                  {{ settDate1 }}
                </v-chip>
              </template>
            </div>
          </div>

          <div style="width: 100%">
            <div class="d-flex align-center">
              <div>
                <v-btn-toggle
                  style="width: 150px;"
                  v-model="dateCcy2"
                  color="accent"
                  dense
                  mandatory
                  borderless
                >
                  <v-btn :value="0" :disabled="isQuoteRequested || (selectedParity === 'JPY')">
                    D0
                  </v-btn>
                  <v-btn :value="1" :disabled="isQuoteRequested || (selectedParity === 'JPY')">
                    D1
                  </v-btn>
                  <v-btn :value="2" :disabled="isQuoteRequested">
                    D2
                  </v-btn>
                </v-btn-toggle>
              </div>

              <template v-if="showSettDateChips">
                <v-chip
                  color="primaryTransp"
                  style="font-size: 15px"
                  class="ml-4"
                >
                  {{ settDate2 }}
                </v-chip>
              </template>
            </div>
          </div>
        </div>
      </template>

      <!-- END SETTLEMENTS -->

      <div class="d-flex mb-5">
        <div style="width: 100%">
          <v-text-field
            ref="amount"
            v-money="money"
            v-model.lazy="amount"
            label="Amount"
            color="accent"
            style="width: 300px; margin-right: 15px"
            reverse
            required
            filled
            rounded
            dense
            :rules="[
              rules.amountValid
            ]"
            @keyup="rules.amountValid = true"
          >
          </v-text-field>
        </div>
        <div style="width: 100%">
          <v-text-field
            :counter="200"
            style="width: 300px"
            v-model="notes"
            label="Notes"
            color="accent"
            clearable
            filled
            rounded
            dense
            :rules="[
              (v) =>
                !v ||
                v.length <= 200 ||
                'Notes must be less than 200 characters',
            ]"
          >
          </v-text-field>
        </div>
      </div>

      <template v-if="!this.isRfqcloud">
        <div class="d-flex mb-3">
          <div style="width: 95%">
            <div class="d-flex align-center">
              <v-combobox
                v-model="account"
                :items="comboBoxItems"
                :filter="getItemNames"
                item-text="text"
                item-value="value"
                item-color="accent"
                @change="setSelectedItem($event)"
                :label="comboBoxTitle"
                color="accent"
                class="orange--text"
                clearable
                filled
                rounded
                disable-lookup
                return-object
                required
                :rules="[(v) => !!v || 'Counterparty is required']"
              >
              </v-combobox>

              <div class="mb-7 ml-3" v-if="!this.isRfqcloud && isBlotterFXSpot">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="accent"
                      rounded
                      :disabled="true || isQuoteRequested || (selectedCounterpartyCnpj === undefined)"
                      v-bind="attrs"
                      v-on="on"
                      @click="updateSpreads"
                      small
                    >
                      <div style="color: black; font-size: 14px">SPREADS</div>
                    </v-btn>
                  </template>
                  <span>Update the spreads</span>
                </v-tooltip>
              </div>

              <div class="mb-1 ml-3 d-flex" v-if="!this.isRfqcloud && !isBlotterFXSpot">
                <v-text-field 
                  ref="spread"
                  v-model="spread" 
                  :disabled="true || !isQuoteRequested"
                  type="number"
                  label="Spread"
                  style="width: 110px; margin-right: 15px"
                  required
                  filled
                  rounded
                  dense
                  :readonly="true"
                >
                </v-text-field>

                <div class="d-flex flex-column">
                  <v-btn 
                    :disabled="true || !isQuoteRequested"
                    color="accent"
                    icon
                    small
                    @click="addSpread"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn 
                    :disabled="true || !isQuoteRequested"
                    color="accent"
                    icon
                    small
                    @click="subtractSpread"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </div>
              </div>

            </div>
          </div>
        </div>
      </template>

      <!-- QUOTE BUTTON -->
      <div
        class="d-flex justify-center mb-4"
      >
        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="accent"
                rounded
                :disabled="isQuoteRequested"
                :loading="loadingQuote"
                v-bind="attrs"
                v-on="on"
                @click="validateRFQToolForm"
                small
              >
                <div style="color: black; font-size: 14px">QUOTE</div>
              </v-btn>
            </template>
            <span>Request for a quote</span>
          </v-tooltip>
        </div>
      </div>
    </v-form>
  </v-sheet>
</template>

<script>
import { VMoney } from "v-money";
import { mapState, mapActions } from "pinia"
import { useAuthStore, useRfqFormStore } from "./../../store/index"

export default {
  watch: {
    isFxndf: {
      handler (newValue) {
        if (newValue) {
          this.setInitDateValues()
        }
      }
    }
  },
  props: {
    isQuoteRequested: { // TODO: rename to disableForm or disable
      type: Boolean,
      required: false,
      default: false
    },
    loadingQuote: {
      type: Boolean,
      required: false,
      default: false
    },
    comboBoxItems: {
      type: Array,
      required: true,
      default: function () {
        return []
      }
    },
    comboBoxTitle: {
      type: String,
      required: false,
      default: function () {
        return ""
      }
    },
    isFxndf: {
      type: Boolean,
      required: false,
      default: false
    },
    rfqcloudUser: {
      required: false
    },
    enableBrlCcy1: {
      default: false
    },
    settDates: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    }
  },
  directives: {
    money: VMoney,
  },
  data () {
    return {
      valid: undefined,
      selectedParity: 'USD',
      rules: {
        amountValid: true
      },
      money: {
        decimal: ".",
        thousands: ",",
      },
      clientSide: undefined,
      dateCcy1: undefined,
      settlementCCY1: "2023-04-25",
      dateCcy2: undefined,
      settlementCCY2: "2023-04-25",
      menuCcy1: false,
      menuCcy2: false,
      date1: undefined,
      date2: undefined,
      amount: undefined,
      notes: undefined,
      account: undefined,
      selectedCounterparty: undefined,
      selectedCounterpartyCnpj: undefined,
      rfqTakerTimeUnix: undefined,
      parities: [ "USDBRL", "EURBRL", "JPYBRL" ],
      selectFixSource: ["PTAX"],
      exchangeProvider: undefined,
      spread: undefined,
      brlCcy1: false,
      // isRfqcloud: false
    }
  },
  computed: {
    ...mapState(useAuthStore, [
      "isBlotterFXSpot"
    ]),
    isRfqcloud() {
      if ((this.rfqcloudUser !== undefined && this.rfqcloudUser !== null) && Object.keys(this.rfqcloudUser).length > 0) {
        return true 
      }
      return false
    },
    showSettDateChips () {
      return (!this.isFxndf && Object.keys(this.settDates).length > 0)
    },
    settDate1 () {
      const settDate = this.dateCcy1
      const parity = this.selectedParity.toLowerCase()
      return this.settDates[parity][settDate]
    },
    settDate2 () {
      const settDate = this.dateCcy2
      return this.settDates.brl[settDate]
    }
  },
  // watch: {
  //   rfqcloudUser: {
  //     handler(value) {
  //       if ((value !== undefined && value !== null) && Object.keys(value).length > 0) {
  //         this.isRfqcloud = true
  //       }
  //     }
  //   }
  // },
  mounted() {
    this.setInitDateValues();
    this.selectedParity = this.parities[0].slice(0, 3);
  },
  methods: {
    ...mapActions(useRfqFormStore, [
      "getTransactionSpread",
      "putTransactionSpread"
    ]),
    setSelectedItem(item) {
      if (item && !this.isRfqcloud) {
        const [counterparty, cnpj] = item.value.split(' ; ');
        this.selectedCounterparty = counterparty
        this.selectedCounterpartyCnpj = cnpj
      } else if (item && this.isRfqcloud) {
        this.exchangeProvider = item.value
      }
    },
    setInitDateValues() {
      // let date = new Date();
      // let dateToIsoString = date.toISOString().split("T")[0]
      // this.dateCcy1 = dateToIsoString;
      // this.dateCcy2 = dateToIsoString;
      // this.date1 = dateToIsoString;
      // this.date2 = dateToIsoString;
      // Date.prototype.addDays = function(days) {
      //   const date = new Date(this.valueOf());
      //   date.setDate(date.getDate() + days);
      //   return date;
      // }

      // let daysToSum = 30
      let date = new Date();
      // date1 = date1.addDays(daysToSum);
      let dateToIsoString = date.toISOString().split("T")[0]
      // if (this.isWeekend(date1ToIsoString)) {
      //   daysToSum += 2
      //   date1 = date1.addDays(1);
      //   date1ToIsoString = date1.toISOString().split("T")[0]
      //   this.isWeekend(date1ToIsoString) ? date1.addDays(1) : daysToSum -= 1
      // }
      // date1ToIsoString = date1.toISOString().split("T")[0]
      // let date2 = new Date();
      // date2 = date2.addDays(daysToSum+1);
      // let date2ToIsoString = date2.toISOString().split("T")[0]
      // if (this.isWeekend(date2ToIsoString)) {
      //   date2 = date2.addDays(1);
      //   date2ToIsoString = date2.toISOString().split("T")[0]
      //   this.isWeekend(date2ToIsoString) ? date2.addDays(1) : false
      // }
      // date2ToIsoString = date2.toISOString().split("T")[0]
      this.dateCcy1 = dateToIsoString
      this.dateCcy2 = dateToIsoString
      this.date1 = dateToIsoString
      this.date2 = dateToIsoString
      // this.firstValidFixingDate = date1ToIsoString
      // this.firstValidMaturityDate = date2ToIsoString
    },
    validateRFQToolForm() {
      let amount = this.amount.replaceAll(",", "")
      amount = Number(amount)
      let amountValid = true
      if (amount === 0) {
        this.rules.amountValid = "Amount value must be greater than 0"
        amountValid = false
      }
      const isFormValid = this.$refs.form.validate();
      if (isFormValid && amountValid) {
        this.quote();
      }
    },
    updateSpreads() {
      this.$emit("updateSpreads", this.generateNewRfqPayload())
    },
    quote() {
      if (!this.isRfqcloud) {
        this.getTransactionSpread().then((response) => {
          this.spread = response.data
        })
      }
      // this.isQuoteRequested = true;
      // this.loadingQuote = true;
      const payload = this.generateNewRfqPayload();
      // this.newRfqPayload = payload;
      this.$emit("quote", payload)
      // this.newRfq(payload)
      //   .then((response) => {
      //     const data = response.data;
      //     this.newRfqResponse = data;
      //     const id = data.rfq_id;
      //     this.listenQuoteFeed(id);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     this.isQuoteRequested = false;
      //     this.loadingQuote = false;
      //   });
    },
    resetFormFields() {
      // this.loadingAcceptQuote = false;
      // this.isQuoteRequested = false;
      this.setInitDateValues();
      this.clientSide = 1;
      this.selectedParity = this.parities[0].slice(0, 3);
      this.account = null;
      this.notes = null;
      this.selectedCounterpartyCnpj = undefined
      // reset amount
      // https://github.com/vuejs-tips/v-money/issues/44#issuecomment-697042550
      const amountInput = this.$refs.amount.$el.querySelector("input");
      amountInput.value = null;
      this.rules.amountValid = true
      this.$refs.form.resetValidation();
    },
    generateNewRfqPayload() {
      this.rfqTakerTimeUnix = Date.now()
      let tickerSymbol = this.selectedParity + "/BRL"
      if (this.brlCcy1) {
        tickerSymbol = 'BRL/BRL'
      }
      const data = {
        rfq_symbol: tickerSymbol,
        rfq_side: this.clientSide,
        rfq_orderqty: this.convertStringToFloat(this.amount),
        rfq_orderqty_ccy: this.selectedParity,
        rfq_settdate_ccy1: this.dateCcy1,
        rfq_settdate_ccy2: this.dateCcy2,
        rfq_taker_id: this.isRfqcloud === true ? this.rfqcloudUser.cnpj : this.selectedCounterpartyCnpj,
        rfq_taker_name: this.isRfqcloud === true ? this.rfqcloudUser.name : this.selectedCounterparty,
        rfq_taker_time: this.rfqTakerTimeUnix,
        rfq_maker_id: "BTBS",
        rfq_maker_name: "BTBS",
        rfq_text: this.notes || "Sent by RFQ Tool"
      }
      if (this.brlCcy1) {
        data.rfq_orderqty_ccy = 'BRL'
      }
      return data
    },
    getItemNames(item, queryText) {
      if (queryText && queryText.length === 0) {
        return false;
      }
      const stringsToCompare = item.value
      const filterName = stringsToCompare.toLowerCase()
      const searchText = queryText.toLowerCase()
      return filterName.indexOf(searchText) > -1
    },
    convertStringToFloat(data) {
      return Number(`${data}`.replaceAll(",", ""));
    },
    isWeekend (date) {
      const dayOfWeek = new Date(`${date}T00:00:00`).getDay()
      const weekend = [0, 6]
      if (weekend.includes(dayOfWeek)) {
        return true
      }
      return false
    },
    disableMaturityDates (val) {
      if (this.isWeekend(val)) {
        return false
      }
      // const date = new Date(`${this.firstValidMaturityDate}T00:00:00`).getTime()
      // const date2 = new Date(`${val}T00:00:00`).getTime()
      // if (date2 < date) {
      //   return false
      // }
      return true
    },
    disableFixingDates (val) {
      if (this.isWeekend(val)) {
        return false
      }
      // const date = new Date(`${this.firstValidFixingDate}T00:00:00`).getTime()
      // const date2 = new Date(`${val}T00:00:00`).getTime()
      // if (date2 < date) {
      //   return false
      // }
      return true
    },
    selectD2Parity () {
      this.dateCcy1 = 2
      this.dateCcy2 = 2
    },
    addSpread() {
      if (this.spread === undefined) {
        return
      }
      this.spread += 5
      this.putTransactionSpread(this.spread).then(() => {
      })
    },
    subtractSpread() {
      if (this.spread === undefined) {
        return
      }
      if ((this.spread - 5) <= 0) {
        this.spread = 0
        return
      }
      this.spread -= 5
      this.putTransactionSpread(this.spread).then(() => {
      })
    }
  }
}
</script>
