<template>
  <div>
    <v-card-text>
      <div style="display: flex; flex-direction: column">
        <v-simple-table dense>
          <tr>
            <td class="header-color cell_title">Product:</td>
            <td style="color: white">{{ transactionDetails.product }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">RFQ ID:</td>
            <td style="color: white">{{ transactionDetails.transaction_id | checkEmpty }}</td>
          </tr>
          <tr v-if="!isRfqCloud">
            <td class="header-color cell_title">Counterparty alias:</td>
            <td style="color: white">{{ transactionDetails.client_id | checkEmpty }}</td>
          </tr>
          <tr v-if="!isRfqCloud">
            <td class="header-color cell_title">Counterparty name:</td>
            <td style="color: white">{{ transactionDetails.client_name | checkEmpty }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Counterparty CNPJ:</td>
            <td style="color: white;">{{ transactionDetails.client_cnpj | formatCNPJ }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Amount CCY:</td>
            <td style="color: white">{{ transactionDetails.amount_ccy | padEnd2 | formatPrice }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Amount BRL:</td>
            <td style="color: white">{{ transactionDetails.amount_brl | padEnd2 | formatPrice }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">CCY:</td>
            <td style="color: white">{{ transactionDetails.currency_code_iso | checkEmpty }}</td>
          </tr>
          <tr v-if="!isRfqCloud">
            <td class="header-color cell_title">CCY code bacen:</td>
            <td style="color: white">{{ transactionDetails.currency_code_bacen | checkEmpty }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Market type:</td>
            <td style="color: white">{{ transactionDetails.market_type | checkEmpty }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Side:</td>
            <td style="color: white;">{{ getSide }}</td>
          </tr>
          <tr v-if="!isRfqCloud">
            <td class="header-color cell_title">USD Parity:</td>
            <td style="color: white">{{ transactionDetails.usd_parity | checkEmpty }}</td>
          </tr>
          <tr
            v-if="transactionDetails.currency_code_iso === 'USD'"
          >
            <td class="header-color cell_title">USDBRL Parity:</td>
            <td style="color: white">{{ transactionDetails.usdbrl_parity | checkEmpty }}</td>
          </tr>
          <tr
            v-if="transactionDetails.currency_code_iso === 'EUR'"
          >
            <td class="header-color cell_title">EURBRL Parity:</td>
            <td style="color: white">{{ transactionDetails.eurbrl_parity | checkEmpty }}</td>
          </tr>
          <tr
            v-if="transactionDetails.currency_code_iso === 'JPY'"
          >
            <td class="header-color cell_title">JPYBRL Parity:</td>
            <td style="color: white">{{ transactionDetails.jpybrl_parity | checkEmpty }}</td>
          </tr>
          <tr>
            <td class="header-color cell_title">Sett BRL:</td>
            <td style="color: white">
              {{ transactionDetails.settlement_date_brl | checkEmpty }} {{ "(" + transactionDetails.settlement_date_brl_dn + ")" | checkEmpty }}
            </td>
          </tr>
          <tr>
            <td class="header-color cell_title">Sett CCY:</td>
            <td style="color: white">
              {{ transactionDetails.settlement_date_ccy | checkEmpty }} {{ "(" + transactionDetails.settlement_date_ccy_dn + ")" | checkEmpty }}
            </td>
          </tr>
          <tr v-if="!isRfqCloud">
            <td class="header-color cell_title">Spot CCYBRL client:</td>
            <td style="color: white">
              {{ transactionDetails.spot_ccybrl_client | checkEmpty }}
            </td>
          </tr>
          <tr v-if="!isRfqCloud">
            <td class="header-color cell_title">Spot CCYBRL cost:</td>
            <td style="color: white">
              {{ transactionDetails.spot_ccybrl_cost | checkEmpty }}
            </td>
          </tr>
          <tr v-if="!isRfqCloud">
            <td class="header-color cell_title">Spot CCYBRL fx:</td>
            <td style="color: white">
              {{ transactionDetails.spot_ccybrl_fx | checkEmpty }}
            </td>
          </tr>
          <tr>
            <td class="header-color cell_title">Trading day:</td>
            <td style="color: white">
              {{ transactionDetails.trading_day | checkEmpty }}
            </td>
          </tr>
          <tr v-if="!isRfqCloud">
            <td class="header-color cell_title">Ack:</td>
            <td style="color: white">
              {{ transactionDetails.ack }}
            </td>
          </tr>
        </v-simple-table>
      </div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: {
    // transactionDetails: {
    //   type: Object,
    //   required: true
    // },
    showTir: {
      type: Boolean,
      default: false
    },
    isRfqCloud: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    formatPrice: (price) => {
      if (!isNaN(price)) {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
      return price
    },
    checkEmpty: (value) => {
      if (value) {
        return value;
      }
      return "-";
    },
    formatCNPJ: (cnpj) => {
      if (cnpj) {
        cnpj = cnpj.toString().split("");
        cnpj.splice(2, 0, ".");
        cnpj.splice(6, 0, ".");
        cnpj.splice(10, 0, "/");
        cnpj.splice(15, 0, "-");

        return cnpj.join("");
      }
    },
    padEnd2: (price) => {
      if (!isNaN(price)) {
        price = price.toString()
        let result = price.split(".")
        if (result[1]) {
          result[1] = result[1].padEnd(2, "0")
          return result[0].concat(".").concat(result[1])
        }
        const decimalPlaces = "".padEnd(2, "0")
        return price.concat(".").concat(decimalPlaces)
      }
      return price
    },
    padEnd4: (price) => {
      if (!isNaN(price)) {
        price = price.toString()
        let result = price.split(".")
        if (result[1]) {
          result[1] = result[1].padEnd(4, "0")
          return result[0].concat(".").concat(result[1])
        }
        const decimalPlaces = "".padEnd(4, "0")
        return price.concat(".").concat(decimalPlaces)
      }
      return price
    },
    padEndJpy: (price) => {
      if (!isNaN(price)) {
        price = price.toString()
        let result = price.split(".")
        if (result[1]) {
          result[1] = result[1].padEnd(6, "0")
          return result[0].concat(".").concat(result[1])
        }
        const decimalPlaces = "".padEnd(6, "0")
        return price.concat(".").concat(decimalPlaces)
      }
      return price
    }
  },
  data () {
    return {
      transactionDetails: {
        "product": undefined,
        "transaction_id": undefined,
        "client_cnpj": undefined,
        "client_id": undefined,
        "side": undefined,
        "currency_code_iso": undefined,
        "amount_ccy": undefined,
        "amount_brl": undefined,
        "spot_ccybrl_cost": undefined,
        "spot_ccybrl_fx": undefined,
        "client_name": undefined,
        "currency_code_bacen": undefined,
        "spot_ccybrl_client": undefined,
        "market_type": undefined,
        "usd_parity": undefined,
        "usdbrl_parity": undefined,
        "eurbrl_parity": undefined,
        "jpybrl_parity": undefined,
        "trading_day": undefined,
        "settlement_date_ccy": undefined,
        "settlement_date_brl": undefined,
        "ack": undefined,
        "settlement_date_brl_dn": undefined,
        "settlement_date_ccy_dn": undefined
      }
    }
  },
  computed: {
    getSide () {
      const buy = "A"
      const sell = "P"
      if (this.isRfqCloud && this.transactionDetails.side === sell) {
        return "Sell"
      }
      if (this.isRfqCloud && this.transactionDetails.side === buy) {
        return "Buy"
      }
      if (this.transactionDetails.side === sell) {
        return "P (Sell)"
      }
      return "A (Buy)"
    }
  },
  methods: {
    setReceipt (data) {
      // this.transactionDetails = JSON.parse(JSON.stringify(this.transactionDetailsTemplateSpot))
      this.transactionDetails.product = data.product
      this.transactionDetails.transaction_id = data.transaction_id
      this.transactionDetails.client_cnpj = data.client_cnpj
      this.transactionDetails.client_id = data.client_id
      this.transactionDetails.side = data.side
      this.transactionDetails.currency_code_iso = data.currency_code_iso
      this.transactionDetails.amount_ccy = data.amount_ccy
      this.transactionDetails.amount_brl = data.amount_brl
      this.transactionDetails.spot_ccybrl_cost = data.spot_ccybrl_cost
      this.transactionDetails.spot_ccybrl_fx = data.spot_ccybrl_fx
      this.transactionDetails.client_name = data.client_name
      this.transactionDetails.currency_code_bacen = data.currency_code_bacen
      this.transactionDetails.spot_ccybrl_client = data.spot_ccybrl_client
      this.transactionDetails.market_type = data.market_type
      this.transactionDetails.usd_parity = data.usd_parity
      this.transactionDetails.usdbrl_parity = data.usdbrl_parity
      this.transactionDetails.eurbrl_parity = data.eurbrl_parity
      this.transactionDetails.jpybrl_parity = data.jpybrl_parity
      this.transactionDetails.trading_day = data.trading_day
      this.transactionDetails.settlement_date_ccy = data.settlement_date_ccy
      this.transactionDetails.settlement_date_brl = data.settlement_date_brl
      this.transactionDetails.settlement_date_ccy_dn = data.settlement_date_ccy_dn
      this.transactionDetails.settlement_date_brl_dn = data.settlement_date_brl_dn
      this.transactionDetails.ack = data.ack
    }
  }
};
</script>

<style scoped>
.v-label {
  font-size: 14px;
}
.cell_title {
  font-weight: 700;
  padding-right: 10px;
}
.custom-cell-value {
  color: #ff9800;
}
.title-table-rfq {
  color: #ff9800;
  font-size: 16px;
}
.header-color {
  color: #ffffffb3;
}
</style>