import axios from "axios"
import axiosRetry from "axios-retry";
// import store from "@/store/index.js"
// import router from "@/router/index.js"
// import { useNotificationsStore, useNetworkStore } from "./store/index"

const axiosInstance = axios.create()

axiosRetry(axiosInstance, { 
  retryDelay: (retryCount) => { retryCount
    // console.log("retryCount: ", retryCount)
    const miliseconds = 1000
    const delayBetweenRetries = miliseconds // retryCount * miliseconds
    return delayBetweenRetries
  }, 
  retries: 10,
  retryCondition: (error) => {
    // const newtworkStore = useNetworkStore()
    // console.log("retryCondition isOffline", newtworkStore.isOffline)
    // if (newtworkStore.isOffline) { 
    //   return false
    // }
    const response = error.response
    if (response !== undefined && response !== null) {
      const status = response.status
      //  if (status >= 400 && status < 600) {
      if (status >= 400 && status < 500) { 
        return false
      }
    }
    // retry no matter what
    return true
  } 
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    // store.commit("setConnectionOffline", false)
    // let xsrfCookieName = "csrf_access_token"
    // if (config.url.includes("/refresh")) {
    //   xsrfCookieName = "csrf_refresh_token"
    // }
    // if (config.withCredentials) {
    //   config.xsrfCookieName = xsrfCookieName
    //   config.xsrfHeaderName = "X-CSRF-TOKEN"
    // }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  // Trigged if status code is 2xx 
  // Do something with response data
  function (response) {
    // const newtworkStore = useNetworkStore()
    // newtworkStore.setOnline()
    return response;
  },
  // Trigged if status codes is not 2xx 
  // Do something with response error
  function (error) {
    // const newtworkStore = useNetworkStore()
    // const isOffline = newtworkStore.isOffline
    const response = error.response
    const method = error.config.method
    // let isLogin = null
    // let isRefresh = null
    let status = null
    if (response) {
      // isLogin = response.config.url.includes("/login")
      // isRefresh = response.config.url.includes("/refresh")
      status = response.status
    }
    if (status === 401) {
      // if (!isLogin && !isRefresh) {
        // return store.dispatch("refreshToken")
        //   .then(() => {
        //     return axiosInstance.request(error.config)
        //   })
      // }
      // store.commit("setAuthenticated", false)
      // router.push("/")
    }
    if (isOffline === false && method !== "get") {
      // const notificationsStore = useNotificationsStore()
      // notificationsStore.sendPutErrorNotification(response)
    }

    if (response === undefined || response === null) {
      console.log("-- CONNECTION OFFLINE --")
      // newtworkStore.setOffline()
    }
    // console.log(error)
    console.log(response)
    return Promise.reject(error);
  }
);

export default axiosInstance