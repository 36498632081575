import { defineStore } from "pinia"
import axiosInstance from "@/http.js"

// import axios from "axios"
const axios = axiosInstance

export const FXSPOT = "fxspot"
export const FXNDF = "fxndf"
export const FXSUPPLIER = "fxsupplier"
export const ROOT = "root"

export let API_PLATFORM_HOST = `@@VUE_APP_API_HOST@@`
export let SECRETKEY = `@@VUE_APP_API_SECRET@@`
export let API_VERSION = `@@VUE_APP_API_VERSION@@`
export let APP_PROD = `@@VUE_APP_PROD@@`
export let API_RFQCLOUD_HOST = `@@VUE_APP_API_RFQCLOUD_HOST@@`
export let RFQCLOUD_API_VERSION = `@@VUE_APP_RFQCLOUD_API_VERSION@@`

if (process.env.NODE_ENV === "development") {
  SECRETKEY = process.env.VUE_APP_API_SECRET
  API_VERSION = process.env.VUE_APP_API_VERSION
  RFQCLOUD_API_VERSION = process.env.VUE_APP_RFQCLOUD_API_VERSION
  API_PLATFORM_HOST = process.env.VUE_APP_API_HOST
  API_RFQCLOUD_HOST = process.env.VUE_APP_API_RFQCLOUD_HOST
  APP_PROD = process.env.VUE_APP_PROD
}



export const API_BLOTTER = `apiv${API_VERSION}/octax/trading/blotter`
export const API_CONTROLPLANE = `apiv${API_VERSION}/octax/controlplane`
export const API_RFQCLOUD=`apiv${RFQCLOUD_API_VERSION}/rfqcloud`


export const EventSourcePolyfill = require("eventsource");

export const useAuthStore = defineStore("auth", {
  state: () => ({
    authenticated: undefined,
    authinfo: undefined,
    blotter: undefined, // TODO: rename to product
    fxspot: FXSPOT,
    fxndf: FXNDF,
    fxsupplier: FXSUPPLIER,
    root: ROOT
  }),
  getters: {
    getAuth () {
      return this.authinfo
    },
    isUserAuthenticated () {
      return this.authenticated
    },
    getBlotter () {
      return this.blotter
    },
    getSubtitle () {
      const subtitles = {
        fxspot: "FXSpot",
        fxndf: "FXNDF",
      }
      const subtitle = subtitles[this.getBlotter]
      return subtitle
    },
    isBlotterFXSpot() {
      return this.blotter === this.fxspot
    },
    isBlotterFXNDF() {
      return this.blotter === this.fxndf
    }
  },
  actions: {
    // setAuthenticated () {
    //   console.log("IS AUTHENTICATED FROM $AUTH" + this.$auth.isAuthenticated)
    //   let isAuthenticated = this.$auth.isAuthenticated
    //   if (isAuthenticated) {
    //     isAuthenticated = JSON.parse(isAuthenticated)
    //   }
    //   this.authenticated = isAuthenticated
    // },
    // setUser () {
    //   let user = localStorage.getItem("user")
    //   if (user) {
    //     user = JSON.parse(user)
    //   }
    //   this.user = user
    // },
    setAuthInfo () {
      let authinfo = localStorage.getItem('AuthCredentials')
      if (authinfo) {
        authinfo = JSON.parse(authinfo)
      }
      this.authinfo = authinfo
    },
    setBlotter () {
      let blotter = localStorage.getItem("blotter")
      if (blotter) {
        blotter = JSON.parse(blotter)
      }
      this.blotter = blotter
    },
    setFXProduct (product) {
      this.blotter = product
      localStorage.setItem("blotter", product)
    },
    setFXSpotProduct () {
      const product = "fxspot"
      this.setFXProduct(product)
    },
    setFXNDFProduct () {
      const product = "fxndf"
      this.setFXProduct(product)
    },
    removeAuthInfo () {
      // this.blotter = null
      this.authenticated = false
      localStorage.removeItem("blotter")
      localStorage.removeItem("AuthCredentials")
      localStorage.removeItem("isUserAuthenticated")
    },
    async loginHandshake (oauth_user_id) {
      try{
        let url = `${API_RFQCLOUD_HOST}/${API_RFQCLOUD}/auth0/handshake/${oauth_user_id}`
        let response = await axios.get(url, {
          headers : {"SECRETKEY": SECRETKEY}
        })
        let authCredentials = response.data
        this.authinfo = authCredentials
        this.authenticated = true
        const isFXSpotEnabled = authCredentials.allowed_products.fxspot
        const isFXNDFEnabled = authCredentials.allowed_products.fxndf
        if (isFXSpotEnabled) {
          this.setFXSpotProduct()
        }
        else if (isFXNDFEnabled) {
          this.setFXNDFProduct()
        }
        localStorage.setItem('AuthCredentials', JSON.stringify(authCredentials))
        localStorage.setItem('isUserAuthenticated', JSON.stringify(this.authenticated))
      } catch (error) {
        console.log(error)
      }
    },
  }
})

export const useDisplayStore = defineStore("display", {
  state: () => ({
    mobile: undefined,
    breakpoint: undefined
  }),
  getters: {
    isMobile() {
      return this.mobile
    },
    showLayoutElements () {
      const blackListRoutes = [
        "/",
        "/portal"
      ]
      const authStore = useAuthStore()
      return (routePath) => authStore.isUserAuthenticated && !blackListRoutes.includes(routePath)
    },
    contentHeight () {
      const contentHeight = 
        this.windowHeight 
        - this.toolbarHeight 
        - this.footerHeight 
        - this.marginLayout // x axis
        - this.marginLayout // y axis
      return `${contentHeight}`
    },
    currentBreakpoint () {
      return this.breakpoint
    },
    isXLarge () {
      return this.currentBreakpoint === "xl"
    },
    isLarge () {
      return this.currentBreakpoint === "lg"
    },
    isMedium () {
      return this.currentBreakpoint === "md"
    },
    isSmall () {
      return this.currentBreakpoint === "sm"
    },
    isXSmall () {
      return this.currentBreakpoint === "xs"
    },
    isDrawerOpened () {
      return this.drawer
    }
  },
  actions: {
  }
})

export const usePortalStore = defineStore("portal", {
  state: () => ({ }),
  getters: {
  },
  actions: {
  }
})

export const useRfqFormStore = defineStore("rfqForm", {
  state: () => ({
    // mobile: undefined
  }),
  actions: {
    newRfq(data) {
      const authStore = useAuthStore()
      const product = authStore.getBlotter
      const url = `${API_RFQCLOUD_HOST}/rfqtest/rfq/new/${product}`
      return axios.post(url, data)
    },
    nothingDoneRfq(data) {
      const url = `${API_RFQCLOUD_HOST}/rfqtest/rfq/nothingdone`
      return axios.post(url, data)
    },
    newOrderRfq(data) {
      const url = `${API_RFQCLOUD_HOST}/rfqtest/rfq/neworder`
      return axios.post(url, data) 
    },
    getProductFeature() {
      const url = `${API_RFQCLOUD_HOST}/${API_CONTROLPLANE}/license/product_feature`
      return axios.get(url, {
        headers: {
          'SECRETKEY': SECRETKEY
        }
      })
    }
  }
})

export const transactionsStore = defineStore("transactions", {
  state: () => ({
    fxspotTransactions: [],
    fxspotTransactionsLoading: false,
    fxspotTransactionsCount: 0,
    fxndfTransactions: [],
    fxndfTransactionsLoading: false,
    fxndfTransactionsCount: 0
  }),
  getters: {
    getFxspotTransactions(state) {
      return state.fxspotTransactions
    }
  },
  actions: {
  }
})
