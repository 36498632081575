import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors"

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,
    themes: {
      light: {
        primary: "#007BFF",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      },
      dark: {
        primary: colors.grey.darken4, // "#1c0021",
        primaryLighten: colors.grey.darken3,
        secondary: colors.blue.lighten1, // colors.grey.darken2, // colors.lightBlue.darken2, //  "#00a9d4",
        accent: colors.orange.base,// "#40ffdc",
        attention: colors.red.accent3,
        alive: colors.green.accent3,
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      },
    },
  },
});
